import React from 'react';
import image from '../../images/family.png'

const NewHeader = ({ heading, content, dark, children }) => {
    return <section id="values" className="text-gray-100 body-font" >
        <div className="bg-cover bg-center" style={{ backgroundImage: `url(${image})` }}>
            <div className={`py-16 ${dark == 'true' ? 'bg-slate-700/80' : 'bg-blue-500/50'}`}>
                <div className="container mx-auto px-5">
                    <div className="">
                        <h1 className="text-7xl text-white mb-6 lg:w-1/2">{heading}</h1>
                        <p className="text-blue-50 lg:w-2/3">{content}</p>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

NewHeader.defaultProps = {
    heading: "Default Heading",
    content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Temporibus dicta possimus nemo amet dolorum delectus fugit exercitationem, sint illo doloremque qui provident deleniti nobis. Cumque expedita facilis natus pariatur libero.",
    dark: "false"
}

export default NewHeader;
