import React from 'react'

import { BuildingOfficeIcon, BookOpenIcon, } from '@heroicons/react/24/outline'
import Button from '../elements/Button'
import { CheckIcon } from '@heroicons/react/24/solid'

const retirement = [
    {
        title: "Plan Design",
    },
    {
        title: "Investment Selection & Monitoring",
    },
    {
        title: "Provider Selection",
    },
    {
        title: "Fiduciary Management",
    },
    {
        title: "Employee Education & Guidance",
    },
]

const wellness = [
    {
        title: "On-Demand one-on-one Financial Wellness Coaching",
    },
    {
        title: "Large format group education sessions on financial topics (retirement savings, HSAs, insurance, budgeting, etc.)",
    },
    {
        title: "YNAB Certified Budget Coaching, utilizing the award-winning YNAB budget software",
    },
    {
        title: "Comprehensive financial planning for executives or key employees",
    },
]

const Services2 = () => {

    return (
        <section className="">
            <div className="container mx-auto px-5 py-12">

                < div className="grid lg:grid-cols-2 lg:divide-x" >

                    <div className="flex flex-col py-16 px-2 md:px-12">
                        <div class='w-16 h-16 inline-flex sm:mr-4 sm:mb-0 mb-4 items-center justify-center rounded-full flex-shrink-0 bg-blue-500'>
                            <BookOpenIcon className='h-8 w-8 text-blue-100' />
                        </div>
                        <small className='mt-6 text-blue-500'>Retirement Plan Services</small>
                        {/* <h3 className={`mb-4 ${selected ? '' : 'text-gray-600 group-hover:text-gray-900'}`}>{item.subtitle}</h3> */}
                        <p class='text-left leading-relaxed text-gray-500'>
                            We help you design, implement, and execute a retirement plan for your company. This provides retirement security for you and your employees, and tax benefits for the business.
                        </p>

                        <div className="mt-8">
                            {retirement.map((item) => (
                                <div className="flex items-center mb-4">
                                    <CheckIcon className="h-6 w-6 text-blue-500 mr-2 flex-shrink-0" />
                                    <p className='text-gray-500'>{item.title}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col py-16 px-2 md:px-12">
                        <div class='w-16 h-16 inline-flex sm:mr-4 sm:mb-0 mb-4 items-center justify-center rounded-full flex-shrink-0 bg-blue-500'>
                            <BuildingOfficeIcon className='h-8 w-8 text-blue-100' />
                        </div>
                        <small className='mt-6 text-blue-500'>Employee Wellness</small>
                        {/* <h3 className={`mb-4 ${selected ? '' : 'text-gray-600 group-hover:text-gray-900'}`}>{item.subtitle}</h3> */}
                        <p class='grow text-left leading-relaxed text-gray-500'>
                            Our Financial Wellness team partners with you to provide your employees the education, motivation, and tools to flourish financially. We are committed to providing holistic financial wellness by engaging with your people one-on-one to answer their specific questions and offer guidance on financial best practices.
                        </p>

                        <div className="mt-8">
                            {wellness.map((item) => (
                                <div className="flex items-top mb-4">
                                    <CheckIcon className="h-6 w-6 text-blue-500 mr-2 flex-shrink-0" />
                                    <p className='text-gray-500'>{item.title}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                </ div>
            </div >

        </section >
    )
}

export default Services2
