import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Cta from '../../components/sections/Cta'
import Services2 from '../../components/sections/Services2'
import NewHeader from '../../components/sections/NewHeader'
import SplitImage2 from '../../components/sections/SplitImage2'

const business = () => {
    return (
        <Layout>

            <Seo title="Business Services" />
            <SplitImage2
                imageleft="false"
                heading="Financial Wellness for Your Team"
                subheading="Business Services"
                content="Studies show that financial stress leads to lower productivity, higher absenteeism, and higher health care costs. This hurts your business, but more importantly it hurts the members of your team who are living through it. As the leader of your organization, you can make a difference in the lives of your employees by helping improve their financial lives beyond providing them with a paycheck." />
            <Services2 />
            <Cta />
        </Layout>
    )
}

export default business
